document.addEventListener('DOMContentLoaded', function () {
  window.addEventListener('scroll', function () {
    const nav = document.querySelector('header > nav')
    const fixedBottom = document.querySelector('.fixed-bottom')

    if (window.pageYOffset > 50) {
      nav?.classList.add('nav-background')
      fixedBottom?.classList.remove('d-none')
    } else {
      nav?.classList.remove('nav-background')
      fixedBottom?.classList.add('d-none')
    }
  })

  document
    .getElementById('pagetop')
    ?.addEventListener('click', function func() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    })

  document.querySelectorAll('.nav-item.dropdown').forEach((parent) => {
    parent.addEventListener('mouseover', function () {
      parent.classList.add('show')

      parent.querySelectorAll('a').forEach((child) => {
        child.setAttribute('aria-expanded', 'true')
      })

      parent.querySelectorAll('.dropdown-menu').forEach((child) => {
        child.classList.add('show')
      })
    })
  })

  document.querySelectorAll('.nav-item:not(.dropdown)').forEach((parent) => {
    parent.addEventListener('mouseover', function () {
      document.querySelectorAll('.nav-item.dropdown').forEach((dropdown) => {
        dropdown.classList.remove('show')

        dropdown.querySelectorAll('a').forEach((child) => {
          child.setAttribute('aria-expanded', 'false')
        })

        dropdown.querySelectorAll('.dropdown-menu').forEach((child) => {
          child.classList.remove('show')
        })
      })
    })
  })

  document.querySelectorAll('.dropdown-menu').forEach((child) => {
    child.addEventListener('mouseout', function () {
      child.parentElement?.classList.remove('show')
      child.parentElement?.querySelectorAll('a').forEach((x) => {
        x.setAttribute('aria-expanded', 'false')
      })
      child.classList.remove('show')
    })
  })
})
